<template>
  <div>
    <div class="ui-panel-trigger">
      <slot
        name="trigger"
        :open="doOpen"
        :close="doClose"
        :toggle="doToggle"
      ></slot>
    </div>

    <div
      :class="['ui-panel-widget', innerClass, `--${position}`, {'--open': innerOpen}]"
      :style="widgetStyle"
    >
      <div
        class="ui-panel-contents"
        v-if="isLoaded"
      >
        <slot
          name="contents"
          :open="doOpen"
          :close="doClose"
          :toggle="doToggle"
        >
          <!-- final fallback, use default slot as contents -->
          <slot
            name="default"
            :open="doOpen"
            :close="doClose"
            :toggle="doToggle"
          ></slot>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ui-panel',

  props: {
    open: {
      type: Boolean,
      required: false,
      default: false
    },

    position: {
      type: String,
      required: false,
      default: 'bottom',
      validator: value => ['top', 'right', 'bottom', 'left'].includes(value)
    },

    innerClass: {
      required: false,
      default: null
    },

    size: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      innerOpen: this.open,
      widgetEl: null,
      isLoaded: false
    };
  },

  watch: {
    open(newValue) {
      this.innerOpen = newValue;
      if (newValue) {
        this.isLoaded = true;
      }

      if (this.innerOpen) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    }
  },

  computed: {
    widgetStyle() {
      if (!this.size) {
        return null;
      }

      switch (this.position) {
        case 'top':
        case 'bottom':
          return { height: this.size };

        case 'left':
        case 'right':
          return { width: this.size };
      }
    }
  },

  methods: {
    doOpen() {
      this.isLoaded = true;

      this.innerOpen = true;
      this.$emit('update:open', true);
      this.$emit('open');
      console.log('ui panel emit open');
    },

    doClose() {
      this.innerOpen = false;
      this.$emit('update:open', false);
      this.$emit('close');
      console.log('ui panel emit close');
    },

    doToggle() {
      return this.innerOpen ? this.doClose() : this.doOpen();
    }
  },

  mounted() {
    this.widgetEl = this.$el.querySelector('.ui-panel-widget');
    document.body.appendChild(this.widgetEl);
  },

  destroyed() {
    try {
      document.body.removeChild(this.widgetEl);
    } catch (e) {
      // El elemento ya estaba desmontado (?)  pasa en casos cuando se usa <ui-panel v-if="XXX">
    }
  }
};
</script>

<style lang="scss">
.ui-panel-widget {
  transition: opacity var(--ui-duration-snap), transform var(--ui-duration-snap);
  opacity: 0;
  pointer-events: none;
  max-width: 100vw;

  &.--open {
    opacity: 1;
    pointer-events: initial;
  }

  &.--left {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    z-index: 4;

    width: 90vw;

    transform: translateX(-60%);

    .ui-panel-contents {
      position: relative;
      height: 100%;
      overflow-y: auto;
    }

    &.--open {
      transform: translateX(0);
    }
  }

  &.--bottom {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;

    height: 50vh;

    transform: translateY(100%);

    .ui-panel-contents {
      position: relative;
      height: 100%;
      overflow-y: auto;
    }

    &.--open {
      transform: translateY(0);
    }
  }
}
</style>